/* eslint-disable no-mixed-spaces-and-tabs */
import Vue from 'vue';
import VueToast from 'vue-toast-notification';
import VueGoodTablePlugin from 'vue-good-table';
import { ToggleButton } from 'vue-js-toggle-button';
import '@/../public/vendor/fontawesome-free/css/all.min.css';
import '@/../public/vendor/bootstrap/bootstrap.min.css';
import '@/../public/css/kugu-stylesheet.css';
import 'fix-date';
import 'vue-good-table/dist/vue-good-table.css';
import '@/../public/css/vue-good-tables-override.css';
import '@/../public/vendor/bootstrap/bootstrap.bundle.min.js';
import VTooltip from 'v-tooltip';
import Multiselect from 'vue-multiselect';
import VCalendar from 'v-calendar';
import VueApexCharts from 'vue-apexcharts';
import { Simplert } from 'vue2-simplert-plugin';
import * as Sentry from '@sentry/vue';
import { BrowserTracing } from '@sentry/tracing';
import Cy from '@/mixins/cy';
import auth, { fetchMandant } from '@/utils/auth';
import 'vue2-simplert-plugin/dist/vue2-simplert-plugin.min.css';
import '@/mixins/responsiveness';
import Spacer from '@/components/Spacer';
import 'vue-toast-notification/dist/theme-sugar.css';
import Bem from '@/utils/bem';
import Roles from '@/mixins/security/roles';
import App from '@/App';
import Router from '@/router';
import { version } from '@/../package';
import { setUpMatomoDashboard } from '@/utils/matomo';
import VueObserveVisibility from 'vue-observe-visibility';
import '@/assets/styles/gui/_fonts.scss';
import StoreProvider from '@/store';
import { isStagingEnv, isDevEnv, isDemoEnv } from '@/utils/utils';
import { validateKuguEmailAddress } from '@/utils/validators';
import cookieconsentConfig from './utils/cookieconsentConfig';

// the reason for this check is we want to upgrade keycloak to the latest stable version
// and then we will push it to production once we make sure
// everything work as expected on our test environment.
var keycloak;
const isTestingEnv = isStagingEnv || isDevEnv || isDemoEnv;
const getKeycloak = require('@/utils/KeycloakConfig').default;

Vue.use(Simplert);
Vue.component('Multiselect', Multiselect);
Vue.component('Spacer', Spacer);
Vue.component('ToggleButton', ToggleButton);
Vue.use(VueGoodTablePlugin);
Vue.use(VCalendar);
Vue.use(VTooltip);
Vue.use(VueApexCharts);
Vue.use(StoreProvider);
Vue.component('Apexchart', VueApexCharts);
Vue.use(VueToast);
Vue.use(VueObserveVisibility);
Vue.mixin(Bem);
Vue.mixin(Roles);
Vue.mixin(Cy);
Vue.directive('cy', {
  inserted(el, bind, options) {
    const nameComponent = options.context.$options.name.toLocaleLowerCase();
    el.setAttribute(
      'data-cy',
      bind.value
        ? `${nameComponent}${options.context.cySalt || ''}__${bind.value}`
        : `${nameComponent}${options.context.cySalt || ''}`,
    );
  },

  update(el, bind, options) {
    const nameComponent = options.context.$options.name.toLocaleLowerCase();
    el.setAttribute(
      'data-cy',
      bind.value
        ? `${nameComponent}${options.context.cySalt || ''}__${bind.value}`
        : `${nameComponent}${options.context.cySalt || ''}`,
    );
  },
});

Vue.directive('custom-scroll', {
  inserted(el) {
    el.classList.add('custom-scroll');
  },
});

Vue.config.productionTip = false;
const authentication = auth.authentication;
Vue.directive('click-outside', {
  bind(el, binding, vnode) {
    el.clickOutsideEvent = function (event) {
      // here I check that click was outside the el and his children
      if (!(el === event.target || el.contains(event.target))) {
        // and if it did, call method provided in attribute value
        vnode.context[binding.expression](event);
      }
    };
    document.body.addEventListener('click', el.clickOutsideEvent);
  },
  unbind(el) {
    document.body.removeEventListener('click', el.clickOutsideEvent);
  },
});

Vue.filter('useSquareMeter', (value) => value.toString().replace('m2', 'm\u00B2'));

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    Vue,
    dsn: 'https://366ce971e45648e789d2412841fde76a@o1238442.ingest.sentry.io/6389194',
    integrations: [
      new BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(Router),
        tracingOrigins: ['portal.kugu.local', /^\//],
      }),
    ],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.05,
    release: version,
  });
}

if (authentication.NO_AUTH_DOMAIN.indexOf(window.location.host) === -1) {
  let propData;
  (async () => {
    propData = await fetchMandant();
    (async () => {
      keycloak = await getKeycloak.loadKeycloak();
      keycloak
        .init({ onLoad: 'login-required', checkLoginIframe: false })
        .success((auth) => {
          if (!auth) {
            window.location.reload();
          }
          setUpMatomoDashboard();
          new Vue({
            router: Router,
            render: (h) => h(App),
          }).$mount('#app');

          localStorage.setItem(
            authentication.localstorage_user_token,
            keycloak.token,
          );
          localStorage.setItem('vue-refresh-token', keycloak.refreshToken);
          if (!isTestingEnv && !validateKuguEmailAddress(window.keycloak.idTokenParsed.email)) {
            window.cookieconsent.initialise(cookieconsentConfig());
          }
          // exclude all kugu users from matomo
          setInterval(() => {
            keycloak
              .updateToken(70)
              .success((refreshed) => {
                if (refreshed) {
                  window.console.debug(`Token refreshed${refreshed}`);
                } else {
                  const tokenValidityTime = Math.round(
                    keycloak.tokenParsed.exp
                    + keycloak.timeSkew
                    - new Date().getTime() / 1000,
                  );
                  window.console.warn(
                    `Token not refreshed, valid for ${tokenValidityTime} seconds`,
                  );
                }
              })
              .error(() => {
                window.console.error('Failed to refresh token');
              });
          }, 60000);
        })
        .error(() => {
          window.console.error('Authenticated Failed');
        });
    })();

    // if (!isTestingEnv) {
    //   keycloak = window.Keycloak({
    //     url: authentication.server_url,
    //     realm: propData.keycloak_realm,
    //     clientId: authentication.client_id,
    //     redirectUrl: window.location.origin,
    //   });
    // }
    // window.keycloak = keycloak;
    window.current_mandant = propData.mandant_name;
  })();
} else {
  const keycloak = {};
  keycloak.tokenParsed = {};
  keycloak.tokenParsed.given_name = 'Demo';
  keycloak.tokenParsed.family_name = 'User';
  keycloak.tokenParsed.realm_access = {};
  keycloak.tokenParsed.realm_access.roles = ['Portal-Module Billing'];
  localStorage.setItem(authentication.localstorage_user_token, '1');
  window.keycloak = keycloak;
  setUpMatomoDashboard();
  new Vue({
    router: Router,
    render: (h) => h(App),
  }).$mount('#app');
}

export const vue = Vue;
export const currentRealm = function () {
  return window.keycloak.realm;
};
export const currentMandant = function () {
  return window.current_mandant;
};
export const globalStorage = new Vue({
  data: {
    currentProperty: {},
    currentUnitId: '',
    periods: {},
    columnsCustomizations: {},
    hardwareManagerFilters: { address: '' },
    propertyId: { breadcrumb: '', id: '' },
    hardwareId: { breadcrumb: '', id: '' },
    clientId: { breadcrumb: '', id: '' },
    choId: { breadcrumb: '', id: '' },
    customerId: { breadcrumb: '', id: '' },
    trackingConsentSet: false,
    currentEntityInternalIdentifier: '',
    currentEntityIdentifier: '',
    breadCrumbPathParams: {},
    deviceNumberToLabelLookup: {},
    arigoAccess: {},
  },
});

export const eventHub = new Vue();
window.$eventHub = eventHub;
Vue.mixin({
  data() {
    return {
      eventHub,
    };
  },
  methods: {
    scrollTop() {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    },
    scrollToElement(el, parent = window) {
      const elOffsetTop = el.getBoundingClientRect().top;
      parent.scrollTo({
        top: elOffsetTop,
        behavior: 'smooth',
      });
      // parent.scrollTo({ top: 0, behavior: 'smooth' });
    },
    hasUserKeycloakRole: auth.hasUserKeycloakRole,
    canDisplayPage(self) {
      const avaibleRoles = self.$route.meta.roles;
      if (avaibleRoles instanceof Array) return auth.hasUserKeycloakRole(...avaibleRoles);
      return auth.hasUserKeycloakRole(avaibleRoles);
    },
    copyToClipboard(text) {
      const textArea = document.createElement('textarea');
      textArea.textContent = text;
      document.body.append(textArea);
      textArea.select();
      document.execCommand('copy');
      document.body.removeChild(textArea);
    },
  },
});
